import { VerifierType } from './types';

const isString = (value: any) => typeof value === 'string';
const isArray = (value: any) => Array.isArray(value);

const URL_REGEX_DAILYMOTION =
  /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?(?:\?.*)?$/;
const URL_REGEX_FACEBOOK = /facebook\.com\/([^/?].+\/)?video(s|\.php)[/?].*$/;
const URL_REGEX_PLAYABLE = /\.(mp4|og[gv]|webm|mov|m4v)($|\?)/i;
const VIDEO_URL_REGEX_TWITCH = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/;
const CHANNEL_URL_REGEX_TWITCH =
  /(?:www\.|go\.)?twitch\.tv\/([a-z0-9_]+)($|\?)/;
const URL_REGEX_VIMEO = /vimeo\.com\/.+/;
const URL_REGEX_YOUTUBE =
  /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|live\/|watch\?v=|watch\?.+&v=))((\w|-){11})/;

const dailyMotionVerifier: VerifierType = url =>
  isString(url) && URL_REGEX_DAILYMOTION.test(url as string);

const facebookVerifier: VerifierType = url =>
  isString(url) && URL_REGEX_FACEBOOK.test(url as string);

const playableVerifier: VerifierType = url => {
  if (isString(url)) {
    return URL_REGEX_PLAYABLE.test(url as string);
  }
  if (isArray(url)) {
    return (url as Array<string>).some(item => URL_REGEX_PLAYABLE.test(item));
  }

  return false;
};

const twitchVerifier: VerifierType = url =>
  isString(url) &&
  (VIDEO_URL_REGEX_TWITCH.test(url as string) ||
    CHANNEL_URL_REGEX_TWITCH.test(url as string));

const vimeoVerifier: VerifierType = url =>
  isString(url) && URL_REGEX_VIMEO.test(url as string);

const youTubeVerifier: VerifierType = url =>
  isString(url) && URL_REGEX_YOUTUBE.test(url as string);

export const playerVerifiers = {
  dailymotion: dailyMotionVerifier,
  facebook: facebookVerifier,
  playable: playableVerifier,
  twitch: twitchVerifier,
  vimeo: vimeoVerifier,
  youtube: youTubeVerifier,
};
