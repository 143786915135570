import * as React from 'react';
import classnames from 'clsx';
import { useAsyncRef } from '../../../../../providers/useAsyncRef';
import { st, classes } from '../../style/VideoPlayer.st.css';
import { useDidMount } from '../../../../../providers/useDidMount';
import { useChangedEffect } from '../../../../../providers/useChangedEffect';
import { TestIds } from '../../constants';
import { getSDK, vimeoSDK } from '../../../../../providers/ScriptLoader';
import {
  IPlayer,
  IVimeoPlayer,
  IPlayerProps,
  IPlayerHandles,
  VimeoEvent,
} from '../players.types';
import VimeoCover from './VimeoCover';

const EVENTS = {
  PLAY: 'play' as VimeoEvent,
  PAUSE: 'pause' as VimeoEvent,
  END: 'ended' as VimeoEvent,
  VOLUME_CHANGE: 'volumechange' as VimeoEvent,
  PROGRESS: 'timeupdate' as VimeoEvent,
  ERROR: 'error' as VimeoEvent,
};
const usePlayer = (
  container: React.MutableRefObject<HTMLDivElement | null>,
  config: any,
): (() => Promise<IVimeoPlayer>) => {
  const [waitForPlayer, , setPlayer] = useAsyncRef<IVimeoPlayer>();

  useDidMount(() => {
    const waitForSDK = getSDK<IVimeoPlayer>(vimeoSDK);
    waitForSDK
      .then((Vimeo: any) => {
        setPlayer(new Vimeo.Player(container.current, config) as IVimeoPlayer);
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });

    return () => {
      waitForPlayer()
        .then(player => player.destroy())
        .catch(e => {
          // TODO - handle errors properly
          throw e;
        });
    };
  });

  return waitForPlayer;
};

function subscribeToPlayerEvents(
  player: IVimeoPlayer,
  { controls, loop }: Partial<IPlayerProps>,
  {
    onProgress,
    onPlay,
    onPause,
    onEnded,
    onFirstPlay,
    onFirstEnded,
    onError,
  }: Partial<IPlayerProps>,
  currentTimeRef: React.MutableRefObject<number>,
  volumeRef: React.MutableRefObject<number>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  firstPlayStarted: React.MutableRefObject<boolean>,
  setIsPlaynigNow: React.Dispatch<React.SetStateAction<boolean>>,
) {
  player.on(EVENTS.PLAY, () => {
    if (!firstPlayStarted.current) {
      firstPlayStarted.current = true;
      onFirstPlay?.();
    }

    setIsPlaynigNow(true);
    onPlay?.();
  });

  player.on(EVENTS.PAUSE, () => {
    setIsPlaynigNow(false);
    onPause?.();
  });

  player.on(EVENTS.END, () => {
    if (!firstPlayEnded.current) {
      firstPlayEnded.current = true;
      onFirstEnded?.();
    }

    setIsPlaynigNow(false);
    onEnded?.();
  });

  player.on(EVENTS.VOLUME_CHANGE, ({ volume: value }: { volume: number }) => {
    volumeRef.current = value * 100;
  });

  player.on(
    EVENTS.PROGRESS,
    ({ seconds, duration }: { [key: string]: number }) => {
      currentTimeRef.current = seconds;
      onProgress?.(seconds);

      // To prevent from end screen visibility what causes bugs,
      // only for no controls videos
      if (!controls && !loop && duration - seconds < 0.5) {
        player.setCurrentTime(0);
        player.pause();
      }
    },
  );

  if (onError) {
    player.on('error', onError);
  }
}

const useChangedPropsEffects = (
  { src, playing, muted, volume }: Partial<IPlayerProps>,
  firstPlayStarted: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  waitForPlayer: () => Promise<IVimeoPlayer>,
) => {
  useChangedEffect(src, () => {
    firstPlayStarted.current = false;
    firstPlayEnded.current = false;
  });
  useChangedEffect(playing, () =>
    waitForPlayer().then(player => (playing ? player.play() : player.pause())),
  );
  useChangedEffect(muted, () =>
    waitForPlayer().then(player => player.setVolume(muted ? 0 : 1)),
  );
  useChangedEffect(volume, () =>
    waitForPlayer().then(player => player.setVolume(volume! / 100)),
  );
};

const getHandles = (
  waitForPlayer: () => Promise<IVimeoPlayer>,
  isPlayingNow: boolean,
  durationRef: React.MutableRefObject<number | string>,
  currentTimeRef: React.MutableRefObject<number>,
  volumeRef: React.MutableRefObject<number>,
): IPlayerHandles => {
  const handles: IPlayerHandles = {
    play: () => waitForPlayer().then(player => player.play()),
    pause: () => waitForPlayer().then(player => player.pause()),
    togglePlay: () => (isPlayingNow ? handles.pause() : handles.play()),
    getDuration: async () => durationRef.current,
    getCurrentTime: async () => currentTimeRef.current,
    seekTo: time => waitForPlayer().then(player => player.setCurrentTime(time)),
    getVolume: async () => volumeRef.current,
    setVolume: fraction =>
      waitForPlayer().then(player => player.setVolume(fraction / 100)),
    isMuted: async () => volumeRef.current === 0,
    isPlaying: () => isPlayingNow,
    mute: () => waitForPlayer().then(player => player.setVolume(0)),
    unMute: () => waitForPlayer().then(player => player.setVolume(1)),
  };

  return handles;
};

const Player: IPlayer = (props, ref) => {
  const {
    id,
    src,
    playing,
    muted,
    loop,
    showTitle,
    controls = true,
    translations,
    volume = 0,
    onReady,
    onInit,
    onDuration,
    onProgress,
    onPlay,
    onPause,
    onEnded,
    onFirstPlay,
    onFirstEnded,
    onError,
  } = props;
  const [isPlayingNow, setIsPlayingNow] = React.useState<boolean>(!!playing);
  const [title, setTitle] = React.useState<string>('');

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const durationRef = React.useRef<string | number>(0);
  const currentTimeRef = React.useRef<number>(0);
  const volumeRef = React.useRef<number>(volume);

  const firstPlayStarted = React.useRef<boolean>(false);
  const firstPlayEnded = React.useRef<boolean>(false);

  const waitForPlayer = usePlayer(containerRef, {
    url: src,
    autoplay: playing,
    muted,
    loop,
    controls,
    autopause: false,
    title: showTitle,
  });

  useDidMount(() => {
    waitForPlayer()
      .then(player => {
        player
          .ready()
          .then(() => {
            onReady?.();
            player
              .getVideoTitle()
              .then((value: string) => {
                setTitle(value);
              })
              .catch(e => {
                // TODO - handle errors properly
                throw e;
              });

            player
              .getDuration()
              .then((value: number) => {
                durationRef.current = value;
                onDuration?.(value);
              })
              .catch(e => {
                // TODO - handle errors properly
                throw e;
              });
          })
          .catch(e => {
            // TODO - handle errors properly
            throw e;
          });

        subscribeToPlayerEvents(
          player,
          {
            controls,
            loop,
          },
          {
            onProgress,
            onPlay,
            onPause,
            onEnded,
            onFirstPlay,
            onFirstEnded,
            onError,
          },
          currentTimeRef,
          volumeRef,
          firstPlayEnded,
          firstPlayStarted,
          setIsPlayingNow,
        );

        onInit?.(player, 'vimeo');
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });
  });

  useChangedPropsEffects(
    { src, playing, muted, volume },
    firstPlayStarted,
    firstPlayEnded,
    waitForPlayer,
  );

  React.useImperativeHandle(ref, () =>
    getHandles(
      waitForPlayer,
      isPlayingNow,
      durationRef,
      currentTimeRef,
      volumeRef,
    ),
  );

  const onPlayClick = React.useCallback(async () => {
    (await waitForPlayer()).play!();
  }, [waitForPlayer]);

  const onPauseClick = React.useCallback(async () => {
    (await waitForPlayer()).pause!();
  }, [waitForPlayer]);

  return (
    <React.Fragment>
      <div
        ref={containerRef}
        data-player-name="Vimeo"
        data-testid={TestIds.vimeo}
        className={classnames(classes.playerContainer, classes.vimeoContainer)}
      />
      {!controls && (
        <VimeoCover
          id={id}
          showTitle={showTitle}
          title={title}
          hasBeenPlayed={isPlayingNow}
          onPlay={onPlayClick}
          onPause={onPauseClick}
          translations={translations}
          className={st(classes.cover, {
            isMobileView: props.isMobileView,
          })}
        />
      )}
    </React.Fragment>
  );
};

export default React.forwardRef(Player);
