
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "VideoPlayer2054936319",
      {"classes":{"root":"VideoPlayer2054936319__root","playerContainer":"VideoPlayer2054936319__playerContainer","facebookContainer":"VideoPlayer2054936319__facebookContainer","cover":"VideoPlayer2054936319__cover","vimeoContainer":"VideoPlayer2054936319__vimeoContainer","fadeEnter":"VideoPlayer2054936319__fadeEnter","fadeEnterActive":"VideoPlayer2054936319__fadeEnterActive","secondPoster":"VideoPlayer2054936319__secondPoster"},"vars":{},"stVars":{"backgroundColor":"rgba(var(--backgroundColor, 0,0,0), var(--alpha-backgroundColor, 1))","font":"var(--font)","fontColor":"rgba(var(--fontColor, 255,255,255), var(--alpha-fontColor, 1))","borderColor":"rgba(var(--borderColor, 0,0,0), var(--alpha-borderColor, 1))","borderSize":"var(--borderSize)","boxShadow":"var(--boxShadow)","playButtonSize":"var(--playButtonSize)","playButtonMobileSize":"var(--playButtonMobileSize)","textSizeScale":"1px"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  