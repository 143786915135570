import { UniDriver } from '@wix/unidriver-core';
import { PlayerNameType } from '../../VideoPlayer.types';
import { playerVerifiers } from '../verifiers';

const DEFAULT_PLAYER = 'playable';

export const getPlayerName = (url: string | Array<string>): PlayerNameType => {
  for (const key in playerVerifiers) {
    const name = key as PlayerNameType;

    if (playerVerifiers[name](url)) {
      return name;
    }
  }

  return DEFAULT_PLAYER;
};

export const byTestId = (testId: string, context: UniDriver): UniDriver =>
  context.$(`[data-testid="${testId}"]`);
