import * as React from 'react';
import Image from '../../../../Image/viewer/Image';
import { TestIds } from '../../constants';
import { IVideoPlayerTranslations } from '../../../VideoPlayer.types';
import { st, classes } from './PlayableCover.st.css';
import PlayButtonIcon from './PlayButtonIcon.svg';

type PlayableCoverProps = {
  id: string;
  showTitle?: boolean;
  title?: string;
  poster?: {
    uri: string;
    name?: string;
    height: number;
    width: number;
    id?: string;
  };
  hideOverlay?: boolean;
  playing?: boolean;
  description?: string;
  hasBeenPlayed: boolean;
  onPlay: React.MouseEventHandler;
  className?: string;
  translations: IVideoPlayerTranslations;
};

export const PlayOverlay = ({
  title,
  showTitle,
  translations,
}: {
  title?: string;
  showTitle?: boolean;
  translations: IVideoPlayerTranslations;
}) => {
  return (
    <div className={classes.overlay}>
      {showTitle && title && (
        <div
          title={title}
          data-testid={TestIds.title}
          className={classes.title}
        >
          {title}
        </div>
      )}
      <button
        className={classes.playButton}
        aria-label={translations.playVideoAriaLabel}
      >
        <PlayButtonIcon />
      </button>
    </div>
  );
};

const PlayableCover: React.FC<PlayableCoverProps> = ({
  id,
  showTitle,
  title,
  poster,
  hideOverlay,
  playing,
  description,
  hasBeenPlayed,
  onPlay,
  className,
  translations,
}) => {
  if (hideOverlay || playing || hasBeenPlayed) {
    return null;
  }

  return (
    <div
      onClick={onPlay}
      data-testid={TestIds.cover}
      id={`cover-${id}`}
      className={st(classes.root, { transparentOverlay: !poster }, className)}
    >
      {poster && (
        <Image
          id={`img_${id}`}
          {...poster}
          containerId={id}
          displayMode="fill"
          alt={description || ''}
          name=""
        />
      )}
      <PlayOverlay
        title={title}
        showTitle={showTitle}
        translations={translations}
      />
    </div>
  );
};

export default PlayableCover;
