export interface WindowWithScriptLoader extends Window {
  loadScriptPromises?: Partial<{
    [key: string]: any;
  }>;
}

declare let window: WindowWithScriptLoader;

type SDK = {
  name: string;
  loader: (...args: any) => any;
};

export function getSDK<T>(sdk: SDK, ...params: any): Promise<T> {
  window.loadScriptPromises = window.loadScriptPromises || {};
  const loadScriptPromise = window.loadScriptPromises[sdk.name]
    ? window.loadScriptPromises[sdk.name]
    : sdk.loader(...params);

  window.loadScriptPromises[sdk.name] = loadScriptPromise;

  return loadScriptPromise;
}
