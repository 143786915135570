export type DMSdk = {
  player: any;
};

interface WindowWithDMSdk extends Window {
  dmAsyncInit: () => void;
  dailymotion: any;
}

declare let window: WindowWithDMSdk;

// Docs: https://developers.dailymotion.com/sdk/player-sdk/web
const DAILYMOTION_SDK_SRC = 'https://geo.dailymotion.com/libs/player.js';

export const loadScript = () =>
  doesSdkExist()
    ? Promise.resolve(getSdk() as DMSdk)
    : (new Promise(resolve => {
        const previousOnReady = window.dmAsyncInit;
        window.dmAsyncInit = () => {
          if (previousOnReady) {
            previousOnReady();
          }
          resolve(getSdk());
        };

        const script: HTMLScriptElement = createScript();

        script.addEventListener('load', () => resolve(getSdk()));

        window.document.body.insertBefore(
          script,
          window.document.body.firstChild,
        );
      }) as Promise<DMSdk>);

function doesSdkExist() {
  return Boolean(getSdk());
}

function getSdk() {
  return window.dailymotion;
}

function createScript() {
  const script = window.document.createElement('script');
  script.defer = true;
  script.async = true;
  script.src = DAILYMOTION_SDK_SRC;
  return script;
}
