import { withCompController } from '@wix/editor-elements-integrations/src/thunderbolt';
import type {
  IVideoPlayerControllerProps,
  IVideoPlayerMapperProps,
  IVideoPlayerStateRefValues,
  IVideoPlayerProps,
} from '../VideoPlayer.types';
import { mapConsentPolicyStateRefValues } from '../../ConsentPolicyWrapper/viewer/utils';

export default withCompController<
  IVideoPlayerMapperProps,
  IVideoPlayerControllerProps,
  IVideoPlayerProps,
  IVideoPlayerStateRefValues
>(({ stateValues, mapperProps }) => {
  const { reducedMotion } = stateValues;
  return {
    ...mapperProps,
    reducedMotion,
    ...mapConsentPolicyStateRefValues(stateValues),
  };
});
