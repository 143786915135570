import * as React from 'react';
import { useAsyncRef } from '../../../../providers/useAsyncRef';
import { classes } from '../style/VideoPlayer.st.css';
import { useDidMount } from '../../../../providers/useDidMount';
import { useChangedEffect } from '../../../../providers/useChangedEffect';
import { TestIds } from '../constants';
import { getSDK, dailyMotionSDK } from '../../../../providers/ScriptLoader';
import useProgress, { IProgress } from './useProgress';
import {
  IPlayer,
  IDailyMotionPlayer,
  IPlayerProps,
  IPlayerHandles,
} from './players.types';

// player settings reference https://developers.dailymotion.com/api/platform-api/reference/#player-fields
// preconfigured player with autonext: false, autoplay: on
const DAILYMOTION_PLAYER_ID_AUTOPLAY_ON = 'xsfha';
// preconfigured player with autonext: false, autoplay: off
const DAILYMOTION_PLAYER_ID_AUTOPLAY_OFF = 'xsfh8';

const URL_REGEX =
  /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?(?:\?.*)?$/;
const getVideoId = (src: string): string => src.match(URL_REGEX)![1];

const usePlayer = (
  dmPlayerId: string,
  config: any,
): [() => Promise<IDailyMotionPlayer>, () => IDailyMotionPlayer | void] => {
  const [waitForPlayer, getPlayer, setPlayer] =
    useAsyncRef<IDailyMotionPlayer>();

  useDidMount(() => {
    const waitForSDK = getSDK<IDailyMotionPlayer>(dailyMotionSDK);
    waitForSDK
      .then((dailymotion: any) => {
        setPlayer(
          dailymotion.createPlayer(dmPlayerId, config) as IDailyMotionPlayer,
        );
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });
  });

  return [waitForPlayer, getPlayer];
};

function subscribeToEvents(
  getPlayer: () => IDailyMotionPlayer,
  {
    onReady,
    onPlay,
    onPause,
    onEnded,
    onDuration,
    onProgress,
    onFirstPlay,
    onFirstEnded,
    onError,
  }: Partial<IPlayerProps>,
  progress: IProgress,
  isPlayingNow: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  firstPlayStarted: React.MutableRefObject<boolean>,
) {
  progress.subscribe(() => {
    if (onProgress) {
      getPlayer()
        .getState()
        .then(state => onProgress(state?.videoTime || 0));
    }
  });

  return {
    apiready: () => onReady?.(),
    durationChange: () => {
      if (onDuration) {
        getPlayer()
          .getState()
          .then(state => onDuration(state.videoDuration));
      }
    },
    playing: () => {
      if (!firstPlayStarted.current) {
        firstPlayStarted.current = true;
        onFirstPlay?.();
      }

      isPlayingNow.current = true;
      onPlay?.();

      progress.update();
    },
    pause: () => {
      isPlayingNow.current = false;
      onPause?.();

      progress.stop();
    },
    video_end: () => {
      if (!firstPlayEnded.current) {
        firstPlayEnded.current = true;
        onFirstEnded?.();
      }

      isPlayingNow.current = false;
      onEnded?.();

      progress.stop();
    },
    error: (event: Error) => onError && onError(event),
  };
}

const useChangedPropsEffects = (
  { src, playing, muted, volume }: Partial<IPlayerProps>,
  firstPlayStarted: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  waitForPlayer: () => Promise<IDailyMotionPlayer>,
) => {
  useChangedEffect(src, () => {
    firstPlayStarted.current = false;
    firstPlayEnded.current = false;
  });
  useChangedEffect(playing, () =>
    waitForPlayer().then(player => (playing ? player.play() : player.pause())),
  );
  useChangedEffect(muted, () =>
    waitForPlayer().then(player => player.setMute(muted!)),
  );
  useChangedEffect(volume, () =>
    waitForPlayer().then(player => player.setVolume(volume! / 100)),
  );
};

const getHandles = (
  waitForPlayer: () => Promise<IDailyMotionPlayer>,
  getPlayer: () => IDailyMotionPlayer | void,
  isPlayingNow: React.MutableRefObject<boolean>,
): IPlayerHandles => {
  const handles: IPlayerHandles = {
    play: () => waitForPlayer().then(player => player.play()),
    pause: () => waitForPlayer().then(player => player.pause()),
    togglePlay: () => (isPlayingNow.current ? handles.pause() : handles.play()),
    getDuration: async () => {
      const playerState = await getPlayer()?.getState();
      return playerState ? playerState.videoDuration || 0 : 0;
    },
    getCurrentTime: async () => {
      const playerState = await getPlayer()?.getState();
      return playerState ? playerState.videoTime || 0 : 0;
    },
    seekTo: time => waitForPlayer().then(player => player.seek(time)),
    getVolume: async () => {
      const playerState = await getPlayer()?.getState();
      return playerState ? playerState.playerVolume * 100 : 0;
    },
    setVolume: fraction =>
      waitForPlayer().then(player => player.setVolume(fraction / 100)),
    isMuted: async () => {
      const playerState = await getPlayer()?.getState();
      return playerState ? playerState.playerIsMuted : true;
    },
    isPlaying: () => isPlayingNow.current,
    mute: () => waitForPlayer().then(player => player.setMute(true)),
    unMute: () => waitForPlayer().then(player => player.setMute(false)),
  };

  return handles;
};

const Player: IPlayer = (props, ref) => {
  const {
    id,
    src,
    playing,
    muted,
    controls,
    volume = 0,
    onReady,
    onInit,
    onDuration,
    showTitle,
    onProgress,
    onPlay,
    onPause,
    onEnded,
    onFirstPlay,
    onFirstEnded,
    onError,
  } = props;
  const dmPlayerId = `${id}-dailymotion`;

  const isPlayingNow = React.useRef<boolean>(false);
  const firstPlayStarted = React.useRef<boolean>(false);
  const firstPlayEnded = React.useRef<boolean>(false);

  const progress = useProgress();

  const videoId = getVideoId(src as string);

  const [waitForPlayer, getPlayer] = usePlayer(dmPlayerId, {
    width: '100%',
    height: '100%',
    video: videoId,
    player: playing
      ? DAILYMOTION_PLAYER_ID_AUTOPLAY_ON
      : DAILYMOTION_PLAYER_ID_AUTOPLAY_OFF,
    params: {
      controls,
      autoplay: playing,
      mute: muted,
      'ui-start-screen-info': showTitle,
      origin:
        typeof window !== 'undefined' &&
        window.location &&
        window.location.origin,
    },
    events: subscribeToEvents(
      () => getPlayer() as IDailyMotionPlayer,
      {
        onReady,
        onPlay,
        onPause,
        onEnded,
        onDuration,
        onProgress,
        onFirstPlay,
        onFirstEnded,
        onError,
      },
      progress,
      isPlayingNow,
      firstPlayEnded,
      firstPlayStarted,
    ),
  });

  useDidMount(() => {
    waitForPlayer()
      .then(player => {
        onInit?.(player, 'dailymotion');
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });
  });

  useChangedPropsEffects(
    { src, playing, muted, volume, controls },
    firstPlayStarted,
    firstPlayEnded,
    waitForPlayer,
  );

  React.useImperativeHandle(ref, () =>
    getHandles(waitForPlayer, getPlayer, isPlayingNow),
  );

  return (
    <div
      className={classes.playerContainer}
      data-player-name="DailyMotion"
      data-testid={TestIds.dailymotion}
    >
      <div id={dmPlayerId} />
    </div>
  );
};

export default React.forwardRef(Player);
