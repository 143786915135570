import * as React from 'react';
import { TestIds } from '../../constants';
import { IVideoPlayerTranslations } from '../../../VideoPlayer.types';
import { st, classes } from '../Playable/PlayableCover.st.css';
import { PlayOverlay } from '../Playable/PlayableCover';

type VimeoCoverProps = {
  id: string;
  showTitle?: boolean;
  title?: string;
  hasBeenPlayed: boolean;
  onPlay: React.MouseEventHandler;
  onPause: React.MouseEventHandler;
  className?: string;
  translations: IVideoPlayerTranslations;
};

const VimeoCover: React.FC<VimeoCoverProps> = ({
  id,
  showTitle,
  title,
  hasBeenPlayed,
  onPlay,
  onPause,
  className,
  translations,
}) => {
  return (
    <div
      onClick={hasBeenPlayed ? onPause : onPlay}
      data-testid={TestIds.cover}
      id={`cover-${id}`}
      className={st(classes.root, { transparentOverlay: true }, className)}
    >
      {!hasBeenPlayed && (
        <PlayOverlay
          title={title}
          showTitle={showTitle}
          translations={translations}
        />
      )}
    </div>
  );
};

export default VimeoCover;
